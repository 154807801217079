export default [{
  "country_code": "+244",
  "country_name_en": "Angola",
  "country_name_cn": "安哥拉",
  "ab": "AO"
}, {
  "country_code": "+62",
  "country_name_en": "Indonesia",
  "country_name_cn": "印度尼西亚",
  "ab": "ID"
}, {
  "country_code": "+855",
  "country_name_en": "Cambodia",
  "country_name_cn": "柬埔寨",
  "ab": "KH"
}, {
  "country_code": "+60",
  "country_name_en": "Malaysia",
  "country_name_cn": "马来西亚",
  "ab": "MY"
}, {
  "country_code": "+234",
  "country_name_en": "Nigeria",
  "country_name_cn": "尼日利亚",
  "ab": "NG"
}, {
  "country_code": "+63",
  "country_name_en": "Philippines",
  "country_name_cn": "菲律宾",
  "ab": "PH"
}, {
  "country_code": "+7",
  "country_name_en": "Russian Federation",
  "country_name_cn": "俄罗斯",
  "ab": "RU"
}, {
  "country_code": "+65",
  "country_name_en": "Singapore",
  "country_name_cn": "新加坡",
  "ab": "SG"
}, {
  "country_code": "+84",
  "country_name_en": "Viet Nam",
  "country_name_cn": "越南",
  "ab": "VN"
}, {
  "country_code": "+86",
  "country_name_en": "China",
  "country_name_cn": "中国",
  "ab": "CN"
}, {
  "country_code": "+56",
  "country_name_en": "Chile",
  "country_name_cn": "智利",
  "ab": "CL"
}, {
  "country_code": "+20",
  "country_name_en": "Egypt",
  "country_name_cn": "埃及",
  "ab": "EG"
}, {
  "country_code": "+90",
  "country_name_en": "Turkey",
  "country_name_cn": "土耳其",
  "ab": "TR"
}, {
  "country_code": "+91",
  "country_name_en": "India",
  "country_name_cn": "印度",
  "ab": "IN"
}, {
  "country_code": "+376",
  "country_name_en": "Andorra",
  "country_name_cn": "安道尔",
  "ab": "AD"
}, {
  "country_code": "+971",
  "country_name_en": "United Arab Emirates",
  "country_name_cn": "阿拉伯联合酋长国",
  "ab": "AE"
}, {
  "country_code": "+93",
  "country_name_en": "Afghanistan",
  "country_name_cn": "阿富汗",
  "ab": "AF"
}, {
  "country_code": "+1268",
  "country_name_en": "Antigua and Barbuda",
  "country_name_cn": "安提瓜和巴布达",
  "ab": "AG"
}, {
  "country_code": "+1264",
  "country_name_en": "Anguilla",
  "country_name_cn": "安圭拉",
  "ab": "AI"
}, {
  "country_code": "+355",
  "country_name_en": "Albania",
  "country_name_cn": "阿尔巴尼亚",
  "ab": "AL"
}, {
  "country_code": "+374",
  "country_name_en": "Armenia",
  "country_name_cn": "亚美尼亚",
  "ab": "AM"
}, {
  "country_code": "+54",
  "country_name_en": "Argentina",
  "country_name_cn": "阿根廷",
  "ab": "AR"
}, {
  "country_code": "+1684",
  "country_name_en": "American Samoa",
  "country_name_cn": "美属萨摩亚",
  "ab": "AS"
}, {
  "country_code": "+43",
  "country_name_en": "Austria",
  "country_name_cn": "奥地利",
  "ab": "AT"
}, {
  "country_code": "+61",
  "country_name_en": "Australia",
  "country_name_cn": "澳大利亚",
  "ab": "AU"
}, {
  "country_code": "+297",
  "country_name_en": "Aruba",
  "country_name_cn": "阿鲁巴",
  "ab": "AW"
}, {
  "country_code": "+994",
  "country_name_en": "Azerbaijan",
  "country_name_cn": "阿塞拜疆",
  "ab": "AZ"
}, {
  "country_code": "+387",
  "country_name_en": "Bosnia and Herzegovina",
  "country_name_cn": "波斯尼亚和黑塞哥维那",
  "ab": "BA"
}, {
  "country_code": "+1246",
  "country_name_en": "Barbados",
  "country_name_cn": "巴巴多斯",
  "ab": "BB"
}, {
  "country_code": "+880",
  "country_name_en": "Bangladesh",
  "country_name_cn": "孟加拉国",
  "ab": "BD"
}, {
  "country_code": "+32",
  "country_name_en": "Belgium",
  "country_name_cn": "比利时",
  "ab": "BE"
}, {
  "country_code": "+226",
  "country_name_en": "Burkina Faso",
  "country_name_cn": "布基纳法索",
  "ab": "BF"
}, {
  "country_code": "+359",
  "country_name_en": "Bulgaria",
  "country_name_cn": "保加利亚",
  "ab": "BG"
}, {
  "country_code": "+973",
  "country_name_en": "Bahrain",
  "country_name_cn": "巴林",
  "ab": "BH"
}, {
  "country_code": "+257",
  "country_name_en": "Burundi",
  "country_name_cn": "布隆迪",
  "ab": "BI"
}, {
  "country_code": "+229",
  "country_name_en": "Benin",
  "country_name_cn": "贝宁",
  "ab": "BJ"
}, {
  "country_code": "+1441",
  "country_name_en": "Bermuda",
  "country_name_cn": "百慕大群岛",
  "ab": "BM"
}, {
  "country_code": "+673",
  "country_name_en": "Brunei Darussalam",
  "country_name_cn": "文莱",
  "ab": "BN"
}, {
  "country_code": "+591",
  "country_name_en": "Bolivia (Plurinational State of)",
  "country_name_cn": "玻利维亚",
  "ab": "BO"
}, {
  "country_code": "+599",
  "country_name_en": "Bonaire, Sint Eustatius and Saba",
  "country_name_cn": "荷兰加勒比区",
  "ab": "BQ"
}, {
  "country_code": "+55",
  "country_name_en": "Brazil",
  "country_name_cn": "巴西",
  "ab": "BR"
}, {
  "country_code": "+1242",
  "country_name_en": "Bahamas",
  "country_name_cn": "巴哈马",
  "ab": "BS"
}, {
  "country_code": "+975",
  "country_name_en": "Bhutan",
  "country_name_cn": "不丹",
  "ab": "BT"
}, {
  "country_code": "+267",
  "country_name_en": "Botswana",
  "country_name_cn": "博茨瓦纳(波札那)",
  "ab": "BW"
}, {
  "country_code": "+375",
  "country_name_en": "Belarus",
  "country_name_cn": "白俄罗斯",
  "ab": "BY"
}, {
  "country_code": "+501",
  "country_name_en": "Belize",
  "country_name_cn": "伯利兹",
  "ab": "BZ"
}, {
  "country_code": "+1",
  "country_name_en": "Canada",
  "country_name_cn": "加拿大",
  "ab": "CA"
}, {
  "country_code": "+243",
  "country_name_en": "Congo, Democratic Republic of the",
  "country_name_cn": "刚果民主共和国(刚果金)",
  "ab": "CD"
}, {
  "country_code": "+236",
  "country_name_en": "Central African Republic",
  "country_name_cn": "中非共和国",
  "ab": "CF"
}, {
  "country_code": "+242",
  "country_name_en": "Congo",
  "country_name_cn": "刚果共和国(刚果布)",
  "ab": "CG"
}, {
  "country_code": "+41",
  "country_name_en": "Switzerland",
  "country_name_cn": "瑞士",
  "ab": "CH"
}, {
  "country_code": "+225",
  "country_name_en": "Cote D'Ivoire",
  "country_name_cn": "科特迪瓦（象牙海岸）",
  "ab": "CI"
}, {
  "country_code": "+682",
  "country_name_en": "Cook Islands",
  "country_name_cn": "库克群岛",
  "ab": "CK"
}, {
  "country_code": "+237",
  "country_name_en": "Cameroon",
  "country_name_cn": "喀麦隆",
  "ab": "CM"
}, {
  "country_code": "+57",
  "country_name_en": "Colombia",
  "country_name_cn": "哥伦比亚",
  "ab": "CO"
}, {
  "country_code": "+506",
  "country_name_en": "Costa Rica",
  "country_name_cn": "哥斯达黎加",
  "ab": "CR"
}, {
  "country_code": "+53",
  "country_name_en": "Cuba",
  "country_name_cn": "古巴",
  "ab": "CU"
}, {
  "country_code": "+238",
  "country_name_en": "Cabo Verde",
  "country_name_cn": "佛得角",
  "ab": "CV"
}, {
  "country_code": "+357",
  "country_name_en": "Cyprus",
  "country_name_cn": "塞浦路斯",
  "ab": "CY"
}, {
  "country_code": "+420",
  "country_name_en": "Czechia",
  "country_name_cn": "捷克",
  "ab": "CZ"
}, {
  "country_code": "+49",
  "country_name_en": "Germany",
  "country_name_cn": "德国",
  "ab": "DE"
}, {
  "country_code": "+253",
  "country_name_en": "Djibouti",
  "country_name_cn": "吉布提",
  "ab": "DJ"
}, {
  "country_code": "+45",
  "country_name_en": "Denmark",
  "country_name_cn": "丹麦",
  "ab": "DK"
}, {
  "country_code": "+1767",
  "country_name_en": "Dominica",
  "country_name_cn": "多米尼克",
  "ab": "DM"
}, {
  "country_code": "+1",
  "country_name_en": "Dominican Republic",
  "country_name_cn": "多米尼加共和国",
  "ab": "DO"
}, {
  "country_code": "+213",
  "country_name_en": "Algeria",
  "country_name_cn": "阿尔及利亚",
  "ab": "DZ"
}, {
  "country_code": "+593",
  "country_name_en": "Ecuador",
  "country_name_cn": "厄瓜多尔",
  "ab": "EC"
}, {
  "country_code": "+372",
  "country_name_en": "Estonia",
  "country_name_cn": "爱沙尼亚",
  "ab": "EE"
}, {
  "country_code": "+291",
  "country_name_en": "Eritrea",
  "country_name_cn": "厄立特里亚",
  "ab": "ER"
}, {
  "country_code": "+34",
  "country_name_en": "Spain",
  "country_name_cn": "西班牙",
  "ab": "ES"
}, {
  "country_code": "+251",
  "country_name_en": "Ethiopia",
  "country_name_cn": "埃塞俄比亚",
  "ab": "ET"
}, {
  "country_code": "+358",
  "country_name_en": "Finland",
  "country_name_cn": "芬兰",
  "ab": "FI"
}, {
  "country_code": "+679",
  "country_name_en": "Fiji",
  "country_name_cn": "斐济",
  "ab": "FJ"
}, {
  "country_code": "+691",
  "country_name_en": "Micronesia (Federated States of)",
  "country_name_cn": "密克罗尼西亚",
  "ab": "FM"
}, {
  "country_code": "+298",
  "country_name_en": "Faroe Islands",
  "country_name_cn": "法罗群岛",
  "ab": "FO"
}, {
  "country_code": "+33",
  "country_name_en": "France",
  "country_name_cn": "法国",
  "ab": "FR"
}, {
  "country_code": "+241",
  "country_name_en": "Gabon",
  "country_name_cn": "加蓬",
  "ab": "GA"
}, {
  "country_code": "+44",
  "country_name_en": "United Kingdom of Great Britain and Northern Ireland",
  "country_name_cn": "英国",
  "ab": "GB"
}, {
  "country_code": "+1473",
  "country_name_en": "Grenada",
  "country_name_cn": "格林纳达",
  "ab": "GD"
}, {
  "country_code": "+995",
  "country_name_en": "Georgia",
  "country_name_cn": "格鲁吉亚",
  "ab": "GE"
}, {
  "country_code": "+594",
  "country_name_en": "French Guiana",
  "country_name_cn": "法属圭亚那",
  "ab": "GF"
}, {
  "country_code": "+233",
  "country_name_en": "Ghana",
  "country_name_cn": "加纳",
  "ab": "GH"
}, {
  "country_code": "+350",
  "country_name_en": "Gibraltar",
  "country_name_cn": "直布罗陀",
  "ab": "GI"
}, {
  "country_code": "+299",
  "country_name_en": "Greenland",
  "country_name_cn": "格陵兰岛",
  "ab": "GL"
}, {
  "country_code": "+220",
  "country_name_en": "Gambia",
  "country_name_cn": "冈比亚",
  "ab": "GM"
}, {
  "country_code": "+224",
  "country_name_en": "Guinea",
  "country_name_cn": "几内亚",
  "ab": "GN"
}, {
  "country_code": "+590",
  "country_name_en": "Guadeloupe",
  "country_name_cn": "瓜德罗普",
  "ab": "GP"
}, {
  "country_code": "+240",
  "country_name_en": "Equatorial Guinea",
  "country_name_cn": "赤道几内亚",
  "ab": "GQ"
}, {
  "country_code": "+30",
  "country_name_en": "Greece",
  "country_name_cn": "希腊",
  "ab": "GR"
}, {
  "country_code": "+502",
  "country_name_en": "Guatemala",
  "country_name_cn": "危地马拉",
  "ab": "GT"
}, {
  "country_code": "+1671",
  "country_name_en": "Guam",
  "country_name_cn": "关岛",
  "ab": "GU"
}, {
  "country_code": "+245",
  "country_name_en": "Guinea-Bissau",
  "country_name_cn": "几内亚比绍共和国",
  "ab": "GW"
}, {
  "country_code": "+592",
  "country_name_en": "Guyana",
  "country_name_cn": "圭亚那",
  "ab": "GY"
}, {
  "country_code": "+852",
  "country_name_en": "Hong Kong",
  "country_name_cn": "中国香港",
  "ab": "HK"
}, {
  "country_code": "+504",
  "country_name_en": "Honduras",
  "country_name_cn": "洪都拉斯",
  "ab": "HN"
}, {
  "country_code": "+385",
  "country_name_en": "Croatia",
  "country_name_cn": "克罗地亚",
  "ab": "HR"
}, {
  "country_code": "+509",
  "country_name_en": "Haiti",
  "country_name_cn": "海地",
  "ab": "HT"
}, {
  "country_code": "+36",
  "country_name_en": "Hungary",
  "country_name_cn": "匈牙利",
  "ab": "HU"
}, {
  "country_code": "+353",
  "country_name_en": "Ireland",
  "country_name_cn": "爱尔兰",
  "ab": "IE"
}, {
  "country_code": "+972",
  "country_name_en": "Israel",
  "country_name_cn": "以色列",
  "ab": "IL"
}, {
  "country_code": "+964",
  "country_name_en": "Iraq",
  "country_name_cn": "伊拉克",
  "ab": "IQ"
}, {
  "country_code": "+98",
  "country_name_en": "Iran (Islamic Republic of)",
  "country_name_cn": "伊朗",
  "ab": "IR"
}, {
  "country_code": "+354",
  "country_name_en": "Iceland",
  "country_name_cn": "冰岛",
  "ab": "IS"
}, {
  "country_code": "+39",
  "country_name_en": "Italy",
  "country_name_cn": "意大利",
  "ab": "IT"
}, {
  "country_code": "+1",
  "country_name_en": "Jamaica",
  "country_name_cn": "牙买加",
  "ab": "JM"
}, {
  "country_code": "+962",
  "country_name_en": "Jordan",
  "country_name_cn": "约旦",
  "ab": "JO"
}, {
  "country_code": "+81",
  "country_name_en": "Japan",
  "country_name_cn": "日本",
  "ab": "JP"
}, {
  "country_code": "+254",
  "country_name_en": "Kenya",
  "country_name_cn": "肯尼亚",
  "ab": "KE"
}, {
  "country_code": "+996",
  "country_name_en": "Kyrgyzstan",
  "country_name_cn": "吉尔吉斯斯坦",
  "ab": "KG"
}, {
  "country_code": "+686",
  "country_name_en": "Kiribati",
  "country_name_cn": "基里巴斯",
  "ab": "KI"
}, {
  "country_code": "+269",
  "country_name_en": "Comoros",
  "country_name_cn": "科摩罗",
  "ab": "KM"
}, {
  "country_code": "+1869",
  "country_name_en": "Saint Kitts and Nevis",
  "country_name_cn": "圣基茨和尼维斯",
  "ab": "KN"
}, {
  "country_code": "+850",
  "country_name_en": "Korea (Democratic People's Republic of)",
  "country_name_cn": "朝鲜",
  "ab": "KP"
}, {
  "country_code": "+82",
  "country_name_en": "Korea, Republic of",
  "country_name_cn": "韩国",
  "ab": "KR"
}, {
  "country_code": "+965",
  "country_name_en": "Kuwait",
  "country_name_cn": "科威特",
  "ab": "KW"
}, {
  "country_code": "+1345",
  "country_name_en": "Cayman Islands",
  "country_name_cn": "开曼群岛",
  "ab": "KY"
}, {
  "country_code": "+7",
  "country_name_en": "Kazakhstan",
  "country_name_cn": "哈萨克斯坦",
  "ab": "KZ"
}, {
  "country_code": "+856",
  "country_name_en": "Lao People's Democratic Republic",
  "country_name_cn": "老挝",
  "ab": "LA"
}, {
  "country_code": "+961",
  "country_name_en": "Lebanon",
  "country_name_cn": "黎巴嫩",
  "ab": "LB"
}, {
  "country_code": "+1758",
  "country_name_en": "Saint Lucia",
  "country_name_cn": "圣卢西亚",
  "ab": "LC"
}, {
  "country_code": "+423",
  "country_name_en": "Liechtenstein",
  "country_name_cn": "列支敦士登",
  "ab": "LI"
}, {
  "country_code": "+94",
  "country_name_en": "Sri Lanka",
  "country_name_cn": "斯里兰卡",
  "ab": "LK"
}, {
  "country_code": "+231",
  "country_name_en": "Liberia",
  "country_name_cn": "利比里亚",
  "ab": "LR"
}, {
  "country_code": "+266",
  "country_name_en": "Lesotho",
  "country_name_cn": "莱索托",
  "ab": "LS"
}, {
  "country_code": "+370",
  "country_name_en": "Lithuania",
  "country_name_cn": "立陶宛",
  "ab": "LT"
}, {
  "country_code": "+352",
  "country_name_en": "Luxembourg",
  "country_name_cn": "卢森堡",
  "ab": "LU"
}, {
  "country_code": "+371",
  "country_name_en": "Latvia",
  "country_name_cn": "拉脱维亚",
  "ab": "LV"
}, {
  "country_code": "+218",
  "country_name_en": "Libya",
  "country_name_cn": "利比亚",
  "ab": "LY"
}, {
  "country_code": "+212",
  "country_name_en": "Morocco",
  "country_name_cn": "摩洛哥",
  "ab": "MA"
}, {
  "country_code": "+377",
  "country_name_en": "Monaco",
  "country_name_cn": "摩纳哥",
  "ab": "MC"
}, {
  "country_code": "+373",
  "country_name_en": "Moldova, Republic of",
  "country_name_cn": "摩尔多瓦",
  "ab": "MD"
}, {
  "country_code": "+382",
  "country_name_en": "Montenegro",
  "country_name_cn": "黑山",
  "ab": "ME"
}, {
  "country_code": "+261",
  "country_name_en": "Madagascar",
  "country_name_cn": "马达加斯加",
  "ab": "MG"
}, {
  "country_code": "+692",
  "country_name_en": "Marshall Islands",
  "country_name_cn": "马绍尔群岛",
  "ab": "MH"
}, {
  "country_code": "+389",
  "country_name_en": "North Macedonia",
  "country_name_cn": "马其顿",
  "ab": "MK"
}, {
  "country_code": "+223",
  "country_name_en": "Mali",
  "country_name_cn": "马里",
  "ab": "ML"
}, {
  "country_code": "+95",
  "country_name_en": "Myanmar",
  "country_name_cn": "缅甸",
  "ab": "MM"
}, {
  "country_code": "+976",
  "country_name_en": "Mongolia",
  "country_name_cn": "蒙古",
  "ab": "MN"
}, {
  "country_code": "+853",
  "country_name_en": "Macao",
  "country_name_cn": "中国澳门",
  "ab": "MO"
}, {
  "country_code": "+222",
  "country_name_en": "Mauritania",
  "country_name_cn": "毛里塔尼亚（毛塔）",
  "ab": "MR"
}, {
  "country_code": "+1664",
  "country_name_en": "Montserrat",
  "country_name_cn": "蒙特塞拉特岛",
  "ab": "MS"
}, {
  "country_code": "+356",
  "country_name_en": "Malta",
  "country_name_cn": "马耳他",
  "ab": "MT"
}, {
  "country_code": "+230",
  "country_name_en": "Mauritius",
  "country_name_cn": "毛里求斯",
  "ab": "MU"
}, {
  "country_code": "+960",
  "country_name_en": "Maldives",
  "country_name_cn": "马尔代夫",
  "ab": "MV"
}, {
  "country_code": "+265",
  "country_name_en": "Malawi",
  "country_name_cn": "马拉维",
  "ab": "MW"
}, {
  "country_code": "+52",
  "country_name_en": "Mexico",
  "country_name_cn": "墨西哥",
  "ab": "MX"
}, {
  "country_code": "+258",
  "country_name_en": "Mozambique",
  "country_name_cn": "莫桑比克",
  "ab": "MZ"
}, {
  "country_code": "+264",
  "country_name_en": "Namibia",
  "country_name_cn": "纳米比亚",
  "ab": "NA"
}, {
  "country_code": "+687",
  "country_name_en": "New Caledonia",
  "country_name_cn": "新喀里多尼亚",
  "ab": "NC"
}, {
  "country_code": "+227",
  "country_name_en": "Niger",
  "country_name_cn": "尼日尔",
  "ab": "NE"
}, {
  "country_code": "+505",
  "country_name_en": "Nicaragua",
  "country_name_cn": "尼加拉瓜",
  "ab": "NI"
}, {
  "country_code": "+31",
  "country_name_en": "Netherlands",
  "country_name_cn": "荷兰",
  "ab": "NL"
}, {
  "country_code": "+47",
  "country_name_en": "Norway",
  "country_name_cn": "挪威",
  "ab": "NO"
}, {
  "country_code": "+977",
  "country_name_en": "Nepal",
  "country_name_cn": "尼泊尔",
  "ab": "NP"
}, {
  "country_code": "+674",
  "country_name_en": "Nauru",
  "country_name_cn": "瑙鲁",
  "ab": "NR"
}, {
  "country_code": "+64",
  "country_name_en": "New Zealand",
  "country_name_cn": "新西兰",
  "ab": "NZ"
}, {
  "country_code": "+968",
  "country_name_en": "Oman",
  "country_name_cn": "阿曼",
  "ab": "OM"
}, {
  "country_code": "+507",
  "country_name_en": "Panama",
  "country_name_cn": "巴拿马",
  "ab": "PA"
}, {
  "country_code": "+51",
  "country_name_en": "Peru",
  "country_name_cn": "秘鲁",
  "ab": "PE"
}, {
  "country_code": "+689",
  "country_name_en": "French Polynesia",
  "country_name_cn": "法属波利尼西亚",
  "ab": "PF"
}, {
  "country_code": "+675",
  "country_name_en": "Papua New Guinea",
  "country_name_cn": "巴布亚新几内亚",
  "ab": "PG"
}, {
  "country_code": "+92",
  "country_name_en": "Pakistan",
  "country_name_cn": "巴基斯坦",
  "ab": "PK"
}, {
  "country_code": "+48",
  "country_name_en": "Poland",
  "country_name_cn": "波兰",
  "ab": "PL"
}, {
  "country_code": "+508",
  "country_name_en": "Saint Pierre and Miquelon",
  "country_name_cn": "圣彼埃尔和密克隆岛",
  "ab": "PM"
}, {
  "country_code": "+1",
  "country_name_en": "Puerto Rico",
  "country_name_cn": "波多黎各",
  "ab": "PR"
}, {
  "country_code": "+351",
  "country_name_en": "Portugal",
  "country_name_cn": "葡萄牙",
  "ab": "PT"
}, {
  "country_code": "+680",
  "country_name_en": "Palau",
  "country_name_cn": "帕劳",
  "ab": "PW"
}, {
  "country_code": "+595",
  "country_name_en": "Paraguay",
  "country_name_cn": "巴拉圭",
  "ab": "PY"
}, {
  "country_code": "+974",
  "country_name_en": "Qatar",
  "country_name_cn": "卡塔尔",
  "ab": "QA"
}, {
  "country_code": "+262",
  "country_name_en": "Reunion",
  "country_name_cn": "留尼汪",
  "ab": "RE"
}, {
  "country_code": "+40",
  "country_name_en": "Romania",
  "country_name_cn": "罗马尼亚",
  "ab": "RO"
}, {
  "country_code": "+381",
  "country_name_en": "Serbia",
  "country_name_cn": "塞尔维亚",
  "ab": "RS"
}, {
  "country_code": "+250",
  "country_name_en": "Rwanda",
  "country_name_cn": "卢旺达",
  "ab": "RW"
}, {
  "country_code": "+966",
  "country_name_en": "Saudi Arabia",
  "country_name_cn": "沙特阿拉伯",
  "ab": "SA"
}, {
  "country_code": "+677",
  "country_name_en": "Solomon Islands",
  "country_name_cn": "所罗门群岛",
  "ab": "SB"
}, {
  "country_code": "+248",
  "country_name_en": "Seychelles",
  "country_name_cn": "塞舌尔",
  "ab": "SC"
}, {
  "country_code": "+249",
  "country_name_en": "Sudan",
  "country_name_cn": "苏丹",
  "ab": "SD"
}, {
  "country_code": "+46",
  "country_name_en": "Sweden",
  "country_name_cn": "瑞典",
  "ab": "SE"
}, {
  "country_code": "+386",
  "country_name_en": "Slovenia",
  "country_name_cn": "斯洛文尼亚",
  "ab": "SI"
}, {
  "country_code": "+421",
  "country_name_en": "Slovakia",
  "country_name_cn": "斯洛伐克",
  "ab": "SK"
}, {
  "country_code": "+232",
  "country_name_en": "Sierra Leone",
  "country_name_cn": "塞拉利昂",
  "ab": "SL"
}, {
  "country_code": "+378",
  "country_name_en": "San Marino",
  "country_name_cn": "圣马力诺",
  "ab": "SM"
}, {
  "country_code": "+221",
  "country_name_en": "Senegal",
  "country_name_cn": "塞内加尔",
  "ab": "SN"
}, {
  "country_code": "+252",
  "country_name_en": "Somalia",
  "country_name_cn": "索马里",
  "ab": "SO"
}, {
  "country_code": "+597",
  "country_name_en": "Suriname",
  "country_name_cn": "苏里南",
  "ab": "SR"
}, {
  "country_code": "+239",
  "country_name_en": "Sao Tome and Principe",
  "country_name_cn": "圣多美和普林西比",
  "ab": "ST"
}, {
  "country_code": "+503",
  "country_name_en": "El Salvador",
  "country_name_cn": "萨尔瓦多",
  "ab": "SV"
}, {
  "country_code": "+963",
  "country_name_en": "Syrian Arab Republic",
  "country_name_cn": "叙利亚",
  "ab": "SY"
}, {
  "country_code": "+268",
  "country_name_en": "Eswatini",
  "country_name_cn": "斯威士兰",
  "ab": "SZ"
}, {
  "country_code": "+1649",
  "country_name_en": "Turks and Caicos Islands",
  "country_name_cn": "特克斯和凯科斯群岛",
  "ab": "TC"
}, {
  "country_code": "+235",
  "country_name_en": "Chad",
  "country_name_cn": "乍得",
  "ab": "TD"
}, {
  "country_code": "+228",
  "country_name_en": "Togo",
  "country_name_cn": "多哥",
  "ab": "TG"
}, {
  "country_code": "+66",
  "country_name_en": "Thailand",
  "country_name_cn": "泰国",
  "ab": "TH"
}, {
  "country_code": "+992",
  "country_name_en": "Tajikistan",
  "country_name_cn": "塔吉克斯坦",
  "ab": "TJ"
}, {
  "country_code": "+670",
  "country_name_en": "Timor-Leste",
  "country_name_cn": "东帝汶",
  "ab": "TL"
}, {
  "country_code": "+993",
  "country_name_en": "Turkmenistan",
  "country_name_cn": "土库曼斯坦",
  "ab": "TM"
}, {
  "country_code": "+216",
  "country_name_en": "Tunisia",
  "country_name_cn": "突尼斯",
  "ab": "TN"
}, {
  "country_code": "+676",
  "country_name_en": "Tonga",
  "country_name_cn": "汤加",
  "ab": "TO"
}, {
  "country_code": "+1868",
  "country_name_en": "Trinidad and Tobago",
  "country_name_cn": "特立尼达和多巴哥",
  "ab": "TT"
}, {
  "country_code": "+886",
  "country_name_en": "Taiwan, Province of China",
  "country_name_cn": "中国台湾",
  "ab": "TW"
}, {
  "country_code": "+255",
  "country_name_en": "Tanzania, United Republic of",
  "country_name_cn": "坦桑尼亚",
  "ab": "TZ"
}, {
  "country_code": "+380",
  "country_name_en": "Ukraine",
  "country_name_cn": "乌克兰",
  "ab": "UA"
}, {
  "country_code": "+256",
  "country_name_en": "Uganda",
  "country_name_cn": "乌干达",
  "ab": "UG"
}, {
  "country_code": "+1",
  "country_name_en": "United States of America",
  "country_name_cn": "美国",
  "ab": "US"
}, {
  "country_code": "+598",
  "country_name_en": "Uruguay",
  "country_name_cn": "乌拉圭",
  "ab": "UY"
}, {
  "country_code": "+998",
  "country_name_en": "Uzbekistan",
  "country_name_cn": "乌兹别克斯坦",
  "ab": "UZ"
}, {
  "country_code": "+1784",
  "country_name_en": "Saint Vincent and the Grenadines",
  "country_name_cn": "圣文森特和格林纳丁斯",
  "ab": "VC"
}, {
  "country_code": "+58",
  "country_name_en": "Venezuela (Bolivarian Republic of)",
  "country_name_cn": "委内瑞拉",
  "ab": "VE"
}, {
  "country_code": "+1284",
  "country_name_en": "Virgin Islands (British)",
  "country_name_cn": "英属维尔京群岛",
  "ab": "VG"
}, {
  "country_code": "+678",
  "country_name_en": "Vanuatu",
  "country_name_cn": "瓦努阿图",
  "ab": "VU"
}, {
  "country_code": "+685",
  "country_name_en": "Samoa",
  "country_name_cn": "萨摩亚",
  "ab": "WS"
}, {
  "country_code": "+967",
  "country_name_en": "Yemen",
  "country_name_cn": "也门",
  "ab": "YE"
}, {
  "country_code": "+26",
  "country_name_en": "Mayotte",
  "country_name_cn": "马约特",
  "ab": "YT"
}, {
  "country_code": "+27",
  "country_name_en": "South Africa",
  "country_name_cn": "南非",
  "ab": "ZA"
}, {
  "country_code": "+260",
  "country_name_en": "Zambia",
  "country_name_cn": "赞比亚",
  "ab": "ZM"
}, {
  "country_code": "+263",
  "country_name_en": "Zimbabwe",
  "country_name_cn": "津巴布韦",
  "ab": "ZW"
}, {
  "country_code": "+970",
  "country_name_en": "Palestine, State of",
  "country_name_cn": "巴勒斯坦",
  "ab": "PS"
}, {
  "country_code": "+474",
  "country_name_en": "Martinique",
  "country_name_cn": "马提尼克",
  "ab": "MQ"
}, {
  "country_code": "+383",
  "country_name_en": "Kosovo",
  "country_name_cn": "科索沃",
  "ab": "XK"
}, {
  "country_code": "+1721",
  "country_name_en": "Sint Maarten (Dutch part)",
  "country_name_cn": "荷属圣马丁",
  "ab": "SX"
}, {
  "country_code": "+5999",
  "country_name_en": "Curaçao",
  "country_name_cn": "库拉索",
  "ab": "CW"
}, {
  "country_code": "+663",
  "country_name_en": "French St Martin",
  "country_name_cn": "法属圣马丁",
  "ab": "MF"
}, {
  "country_code": "+590",
  "country_name_en": "Saint Barthélemy",
  "country_name_cn": "圣巴泰勒米",
  "ab": "BL"
}, {
  "country_code": "+61891",
  "country_name_en": "Cocos (Keeling) Islands",
  "country_name_cn": "科科斯（基林）群岛",
  "ab": "CC"
}, {
  "country_code": "+61891",
  "country_name_en": "Christmas Island",
  "country_name_cn": "圣诞岛",
  "ab": "CX"
}, {
  "country_code": "+500",
  "country_name_en": "Falkland Islands (Malvinas)",
  "country_name_cn": "福克兰群岛（马尔维纳斯）",
  "ab": "FK"
}, {
  "country_code": "+44",
  "country_name_en": "Guernsey",
  "country_name_cn": "格恩西岛",
  "ab": "GG"
}, {
  "country_code": "+500",
  "country_name_en": "South Georgia and the South Sandwich Islands",
  "country_name_cn": "南乔治亚岛和南桑德韦奇岛",
  "ab": "GS"
}, {
  "country_code": "+44",
  "country_name_en": "Isle of Man",
  "country_name_cn": "英国属地曼岛",
  "ab": "IM"
}, {
  "country_code": "+246",
  "country_name_en": "British Indian Ocean Territory",
  "country_name_cn": "英属印度洋领地",
  "ab": "IO"
}, {
  "country_code": "+44",
  "country_name_en": "Jersey",
  "country_name_cn": "泽西岛",
  "ab": "JE"
}, {
  "country_code": "+672",
  "country_name_en": "Norfolk Island",
  "country_name_cn": "诺福克岛",
  "ab": "NF"
}, {
  "country_code": "+683",
  "country_name_en": "Niue",
  "country_name_cn": "纽埃",
  "ab": "NU"
}, {
  "country_code": "+64",
  "country_name_en": "Pitcairn",
  "country_name_cn": "皮特凯恩",
  "ab": "PN"
}, {
  "country_code": "+290",
  "country_name_en": "Saint Helena, Ascension and Tristan da Cunha",
  "country_name_cn": "圣赫勒拿",
  "ab": "SH"
}, {
  "country_code": "+211",
  "country_name_en": "South Sudan",
  "country_name_cn": "南苏丹",
  "ab": "SS"
}, {
  "country_code": "+690",
  "country_name_en": "Tokelau",
  "country_name_cn": "托克劳",
  "ab": "TK"
}, {
  "country_code": "+688",
  "country_name_en": "Tuvalu",
  "country_name_cn": "图瓦卢",
  "ab": "TV"
}, {
  "country_code": "+380",
  "country_name_en": "Holy See",
  "country_name_cn": "梵蒂冈",
  "ab": "VA"
}, {
  "country_code": "+1340",
  "country_name_en": "Virgin Islands (U.S.)",
  "country_name_cn": "美属维尔京群岛",
  "ab": "VI"
}, {
  "country_code": "+681",
  "country_name_en": "Wallis and Futuna",
  "country_name_cn": "瓦利斯和富图纳",
  "ab": "WF"
}];